import React, { useState, useRef, useEffect } from "react";
import ProfileInfo from "./ProfileInfo";
import OptionComponent from "./ProfileOptions";
import {
  FaTelegramPlane,
  FaWhatsapp,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io";
import profileIcon from "./../../assets/images/profileIcon.svg";
import transactionIcon from "./../../assets/images/transactionIcon.svg";
import settingsIcon from "./../../assets/images/settings.svg";
import shareIcon from "./../../assets/images/share.svg";
import InfoIcon from "./../../assets/images/info.svg";
import { useNavigate } from "react-router-dom";
import { UserDetails } from "../../models/UserDetails";
import { RootState } from "../../models/RootState";
import { connect } from "react-redux";

interface ProfileProps {
  userDetails: UserDetails;
}

const ProfilePage: React.FC<ProfileProps> = ({ userDetails }) => {
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [mobDialog, setMobDialog] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [refUrl, setRefUrl] = useState("");

  const menuOptions = [
    {
      icon: profileIcon,
      title: "My Profile",
      description: "Your profile and personal information",
    },
    {
      icon: transactionIcon,
      title: "Transaction History",
      description: "Your transaction history",
    },
    {
      icon: settingsIcon,
      title: "Bet List",
      description: "Checkout your Bets",
    },
    {
      icon: settingsIcon,
      title: "Account Statement",
      description: "Checkout Your Statement",
    },
    {
      icon: InfoIcon,
      title: "FAQ",
      description: "New? check This",
    },
    {
      icon: shareIcon,
      title: "Share",
      description: "Share your referral link",
    },
  ];

  const shareDetails = {
    title: "BotYaPot",
    text: "This is the place to get all sports updates",
  };

  const transactionHistory = () => {
    navigate("/transactions");
  };

  const myProfile = () => {
    navigate("/myprofile");
  };

  const betList = () => {
    navigate("/betlist");
  }

  const accStatement = () => {
    navigate("/account-statement");
  }

  const faq = () => {
    navigate("/faq");
  }

  // const getRefId = async () => {
  //   let response;
  //   try {
  //     response = await API.post(`/users/get-user-details`, {
  //       userKey: sessionStorage.getItem("userKey"),
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }

  //   if (response.data.status !== "RS_OK") {
  //     toast.error("error sharing details");
  //     return;
  //   }

  //   if (response && response.data.userDetails.ReferralCode) {
  //     let code = response.data.userDetails.ReferralCode;
  //     let url = "https://t.me/yapot_bot?start=" + code;
  //     setRefUrl(url);
  //     if (navigator.share) {
  //       navigator
  //         .share({
  //           title: shareDetails.title,
  //           text: shareDetails.text,
  //           url: url,
  //         })
  //         .then(() => {
  //           console.log("Successfully shared");
  //         })
  //         .catch((error) => {
  //           console.error("Error sharing:", error);
  //           // setIsShareModalOpen(true);
  //         });
  //     } else {
  //       setIsShareModalOpen(true);
  //     }
  //   }
  // };

  const handleShare = () => {
    // console.log("userDetails,",userDetails)
    let code = userDetails?.ReferralCode;
    let url = "https://t.me/strike46_bot?start=" + code;
    setRefUrl(url);

  if (userDetails?.MobileNumber){
    if (navigator.share) {
      navigator
        .share({
          title: shareDetails.title,
          text: shareDetails.text,
          url: url,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
          // setIsShareModalOpen(true);
        });
    } else {
      setIsShareModalOpen(true);
    }
  }
  else{
    setMobDialog(true)
  }
  };

  function requestUserContact() {
    try {
      window?.Telegram?.WebApp?.ready();
      window?.Telegram?.WebApp?.requestContact((contact) => {
        if (contact) {
          console.log("Contact received:", contact);
          window.location.reload();
        } else {
          console.log("User declined to share contact.");
        }
      });
    } catch (e) {
      console.log("xc", e)
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsShareModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex overflow-auto flex-col mx-auto w-full mt-[4rem] h-screen">
        <div className="flex flex-col items-center w-full">
          <ProfileInfo />
          {menuOptions.map((option, index) => (
            <OptionComponent
              key={index}
              icon={option.icon}
              title={option.title}
              description={option.description}
              onClick={
                option.title === "Share"
                  ? handleShare
                  : option.title === "Transaction History"
                  ? transactionHistory
                  : option.title === "My Profile"
                  ? myProfile
                  : option.title === "Bet List"
                  ? betList
                  : option.title === "Account Statement"
                  ? accStatement
                  : option.title === "FAQ"
                  ? faq
                  : undefined
              }
            />
          ))}
        </div>
        <div className="h-[200px] p-16"></div>
      </div>

      {mobDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-inputbox-color p-6 rounded-lg w-full max-w-md">
            <h3 className="text-white text-lg mb-4 text-center">Share Your Contact</h3>
            <p className="text-white text-center">
              To refer your friends, please share your mobile number.
            </p>
            <div className="mt-4 flex gap-4">
              <button
                className="w-1/2 bg-meicnbg-color text-white px-4 py-2 rounded hover:bg-gray-700"
                onClick={() => setMobDialog(false)}
              >
                Close
              </button>
              <button
                onClick={requestUserContact}
                className="w-1/2 bg-meicnbg-color text-white px-4 py-2 rounded hover:bg-gray-700"
              >
                Share
              </button>
            </div>
          </div>
        </div>
      )}

      {isShareModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div
            ref={modalRef}
            className="bg-inputbox-color p-6 rounded-lg w-full max-w-md"
          >
            <h3 className="text-white text-lg mb-4 text-center">
              Share Options
            </h3>
            <div className="flex justify-around">
              <button
                className="p-2 hover:text-blue-500"
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      shareDetails.text
                    )}&url=${encodeURIComponent(refUrl)}`,
                    "_blank"
                  )
                }
              >
                <IoLogoTwitter size={24} color="#1DA1F2" /> {/* Twitter Blue */}
              </button>
              <button
                className="p-2 hover:text-blue-700"
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      refUrl
                    )}`,
                    "_blank"
                  )
                }
              >
                <IoLogoFacebook size={24} color="#4267B2" />{" "}
                {/* Facebook Blue */}
              </button>
              <button
                className="p-2 hover:text-green-500"
                onClick={() =>
                  window.open(
                    `whatsapp://send?text=${encodeURIComponent(
                      shareDetails.text
                    )}%20${encodeURIComponent(refUrl)}`,
                    "_blank"
                  )
                }
              >
                <FaWhatsapp size={24} color="#25D366" /> {/* WhatsApp Green */}
              </button>
              <button
                className="p-2 hover:text-blue-400"
                onClick={() =>
                  window.open(
                    `tg://msg_url?url=${encodeURIComponent(
                      refUrl
                    )}&text=${encodeURIComponent(shareDetails.text)}`,
                    "_blank"
                  )
                }
              >
                <FaTelegramPlane size={24} color="#0088cc" />{" "}
                {/* Telegram Blue */}
              </button>
              <button
                className="p-2 hover:text-gray-300"
                onClick={() =>
                  window.open(
                    `mailto:?subject=${encodeURIComponent(
                      shareDetails.title
                    )}&body=${encodeURIComponent(refUrl)}`,
                    "_blank"
                  )
                }
              >
                <FaEnvelope size={24} color="#D44638" /> {/* Mail Red */}
              </button>
              {/* <button
                className="p-2 hover:text-pink-400"
                onClick={() =>
                  window.open(
                    `https://www.instagram.com/share?url=${encodeURIComponent(
                      refUrl
                    )}`,
                    "_blank"
                  )
                }
              >
                <FaInstagram size={24} color="#C13584" />
              </button> */}
            </div>
            <button
              className="mt-4 w-full bg-meicnbg-color text-white px-4 py-2 rounded hover:bg-gray-700"
              onClick={() => setIsShareModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
  };
};

export default connect(mapStateToProps)(ProfilePage);
