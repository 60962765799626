import { useState } from "react";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";
import API from "../../../services";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./NewTicket.scss";
import Pagination from "../Pagination";

interface CustomerSupportProps {

}


const NewTicket: React.FC<CustomerSupportProps> = ({ }) => {
    const location = useLocation();
    const { details } = location.state || {};
    const [newTicket, setNewTicket] = useState<any>(false);
    const [newTicDes, setNewTicDes] = useState<any>("");
    const [newTicCat, setNewTicCat] = useState<any>("");
    const [refId, setRefId] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const navigate = useNavigate();
    const [count, setCount] = useState<any>(0);
    const [bets, setBets] = useState<any>();
    const [transaction, setTransaction] = useState<any>();
    const [selectedBet, setSelectedBet] = useState<any>(null);


    const handleRowSelect = (row: any) => {
        setSelectedBet(row);
        if (newTicCat === "CASINO" || newTicCat === "SPORTS") {
            setRefId(row?.betId)
        } else if (newTicCat === "WITHDRAWAL" || newTicCat === "DEPOSIT" || newTicCat === "CRYPTO_WITHDRAWAL" || newTicCat === "CRYPTO_DEPOSIT") {
            setRefId(row?.referenceId)
        }
    };

    const selectCat = (cat: any) => {
        setNewTicCat(cat);
        setNewTicDes("");
        setPage(1);
        setRowsPerPage(10)
        if (cat === "CASINO" || cat === "SPORTS") {
            getBetList(cat);
        } else if (cat === "WITHDRAWAL" || cat === "DEPOSIT" || cat === "CRYPTO_WITHDRAWAL" || cat === "CRYPTO_DEPOSIT") {
            loadMoreTransactions(cat);
        } else {
            setRefId("");
        }
    };




    if (!details) {
        return <div>No details available</div>;
    }

    const handleBackClick = () => {
        navigate(-1);
    };

    const loadMoreTransactions = async (cat: any) => {

        setLoading(true);
        try {
            const userKey = sessionStorage.getItem("userKey");
            const res = await API.post("/account/get-transactions", {
                userKey: userKey,
                page: page,
                pageSize: rowsPerPage,
                transactionType: cat
            });

            if (res.data.status === "RS_OK") {
                const data = res?.data?.transactions || [];
                setTransaction(data)
                if (page === 0 || page === 1) {
                    setCount(res?.data?.total);
                }
            } else {
                console.error("Error fetching transactions");
            }
        } catch (error) {
            console.error("Failed to load transactions", error);
        } finally {
            setLoading(false);
        }
    };

    const getBetList = async (cat: any) => {

        let response;
        try {
            response = await API.post(`/users/bet-list`, {
                category: cat,
                page: page,
                pageSize: rowsPerPage,
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
            toast.error(response?.data?.errorDescription)
        }
        setBets([]);
        if (response && response.data?.bets) {
            setBets(response?.data?.bets);
            if (page === 0 || page === 1) {
                setCount(response?.data?.total);
            }
        }
        setLoading(false);
    };

    const dateFormat = (time: any) => {
        const matchStartTime = new Date(time);

        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });

        return formattedStartTime
    }

    const handleTicketsuccess = (row: any) => {
        navigate("/ticket-details", { state: { ticketId: row } });
    };


    const createTicket = async () => {


        try {
            const response = await API.post(`/webapp/create-support-ticket`, {
                category: newTicCat,
                description: newTicDes,
                referenceID: refId
            });

            const data = response.data;
            if (data.status !== "RS_OK") {
                toast.error(`${data.errorDescription}`);
                return;
            } else {
                handleTicketsuccess(data?.ticketId)
            }
            setNewTicDes("");
            setNewTicCat("");
            setRefId("");
            setNewTicket(false);
            setSelectedBet({});

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] customer-support">
                <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem] w-full">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={handleBackClick}
                        className="cursor-pointer object-contain shrink-0 self-stretch my-auto w-[12px] aspect-[0.57]"
                    />
                    <h2 className="self-stretch my-auto">Create Ticket</h2>
                </header>
                <div className="w-full mt-3 mb-3">
                    <select
                        className="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color"
                        value={newTicCat} onChange={(e) => selectCat(e.target.value)}>
                        <option value="" disabled>
                            Select Category
                        </option>
                        {details?.map((option: any) => (
                            <option value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                {
                    (newTicCat === "CASINO" || newTicCat === "SPORTS") && bets && (
                        <>
                            {
                                bets?.length > 0 ? (<>
                                    <label className="block mt-1 text-sm text-zinc-400">Please Select a bet from below table to raise the ticket</label>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full border-collapse user-table border-x mt-1">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2 text-left border-b border-t">-</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Status</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">P/L</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Date</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Sport</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Event</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Market</th>
                                                    <th className="px-4 py-2 text-left border-b border-t">Category</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bets?.map((row: any, index: number) => (
                                                    <tr key={index} className="border-b">
                                                        <td className="p-2">
                                                            <input
                                                                type="radio"
                                                                name="betSelection"
                                                                onChange={() => handleRowSelect(row)}
                                                                checked={selectedBet?.betId === row?.betId}
                                                            />
                                                        </td>
                                                        <td className="p-2">{row?.status}</td>
                                                        <td
                                                            className={`p-2 ${row?.netAmout > 0
                                                                ? "text-green-500"
                                                                : row?.netAmout < 0
                                                                    ? "text-red-500"
                                                                    : "text-white"
                                                                }`}
                                                        >
                                                            {row?.netAmout
                                                                ? row?.netAmout % 1 === 0
                                                                    ? row?.netAmout
                                                                    : row?.netAmout.toFixed(2)
                                                                : 0}
                                                        </td>
                                                        <td className="px-2 py-2">{dateFormat(row?.betTime)}</td>
                                                        <td className="p-2">{row?.sportName}</td>
                                                        <td className="p-2">{row?.eventName}</td>
                                                        <td className="p-2">{row?.marketName}</td>
                                                        <td className="p-2">{row?.category}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination currentPage={page} setCurrentPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} eventsCount={count} callBackFunc={() => getBetList(newTicCat)} />
                                </>) : (<>
                                    <div className="block mt-1 text-sm text-white">No Bets Available For this Category</div>
                                </>)
                            }
                        </>
                    )
                }

                {
                    (newTicCat === "WITHDRAWAL" || newTicCat === "DEPOSIT" || newTicCat === "CRYPTO_WITHDRAWAL" || newTicCat === "CRYPTO_DEPOSIT") && transaction && (
                        <>
                            {
                                transaction?.length > 0 ? (
                                    <>
                                        <label className="block mt-1 text-sm text-zinc-400">Please Select an option from below table to raise the ticket</label>
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full border-collapse user-table border-x mt-1">
                                                <thead>
                                                    <tr>
                                                        <th className="px-4 py-2 text-left border-b border-t">-</th>
                                                        <th className="px-4 py-2 text-left border-b border-t">Amount</th>
                                                        <th className="px-4 py-2 text-left border-b border-t">Time</th>
                                                        <th className="px-4 py-2 text-left border-b border-t">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transaction?.map((row: any, index: number) => (
                                                        <tr key={index} className="border-b">
                                                            <td className="p-2">
                                                                <input
                                                                    type="radio"
                                                                    name="betSelection"
                                                                    onChange={() => handleRowSelect(row)}
                                                                    checked={selectedBet?.referenceId === row?.referenceId}
                                                                />
                                                            </td>
                                                            <td className="p-2">{row?.amount}</td>

                                                            <td className="px-2 py-2">{dateFormat(row?.time)}</td>
                                                            <td className="p-2">{row?.status}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination currentPage={page} setCurrentPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} eventsCount={count} callBackFunc={() => loadMoreTransactions(newTicCat)} />
                                    </>
                                ) : (<>
                                    <div className="block mt-1 text-sm text-white">No Transaction Available For this Category</div>
                                </>)
                            }
                        </>
                    )
                }

                <label
                    htmlFor="amount"
                    className="block mt-3 text-sm text-zinc-400"
                >
                    Description
                </label>
                <input
                    type="text"
                    className="px-4 py-3 mt-2.5 mb-1 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                    placeholder="Enter you Issue"
                    value={newTicDes || ""}
                    onChange={(e) => setNewTicDes(e.target.value)}
                />
                {!newTicDes && (
                    <span className="text-sm text-orange-500">
                        Description cannot be empty
                    </span>
                )}

                <div className="flex justify-between mt-4">
                    <button
                        type="submit"
                        className="p-2.5 w-[98%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                        disabled={
                            !newTicCat ||
                            !newTicDes ||
                            ((newTicCat === "DEPOSIT" || newTicCat === "WITHDRAWAL" || newTicCat === "CRYPTO_DEPOSIT" || newTicCat === "CRYPTO_WITHDRAWAL") && !selectedBet)
                        }
                        onClick={() => createTicket()}
                    >
                        Submit
                    </button>
                </div>

                <div className="h-[200px] p-16"></div>
            </div>

        </>
    );
};

export default NewTicket;