import React, { useState } from "react";
import ArrowUp from "./../../../assets/images/arrow-up.svg";
import ArrowDown from "./../../../assets/images/arrow-down.svg";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import API from "../../../services";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from "../../../models/RootState";
import { connect } from "react-redux";

interface TransactionHistoryItemProps {
  type: string;
  title: string;
  amount?: number;
  date: string;
  status: string;
  referenceId: string;
  onClick: () => void; // Add this line
  activeWallet: string;
}

const TransactionHistoryItem: React.FC<TransactionHistoryItemProps> = ({
  type,
  title,
  amount,
  date,
  status,
  referenceId,
  onClick,
  activeWallet
}) => {
  // Determine the appropriate arrow icon based on the transaction type
  const arrowIcon = type === "DEPOSIT" || type === "CRYPTO_DEPOSIT" ? ArrowDown : ArrowUp;
  const statusColor = status === "success" ? "text-green-500" : "text-red-500";
  const [utrNumber, setUtrNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openUtr, setOpenUtr] = useState<any>(false);
  const [openProcess, setOpenProcess] = useState<any>(false);
  const paymentType = ["UPI", "BANK_TRANSFER"]
  const [selectedPType, setSelectedPType] = useState<any>("");

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await API.post(`/account/resubmit-deposit`, {
        refId: referenceId,
        utr: utrNumber,
        tgProviderId: "PG123",
      });

      const data = await response.data;
      if (data?.status !== "RS_OK") {
        toast.error(data?.errorDescription);
      } else {
        toast.success("Success");
      }

      setOpenUtr(false);
    } catch (error) {
      console.log("There was a problem with the fetch operation.");
    } finally {
      setLoading(false);
    }
  };

  const processDeposit = async () => {
    setLoading(true);

    try {
      const response = await API.post(`/account/processDeposit`, {
        uKey: sessionStorage.getItem("userKey"),
        depositType: selectedPType,
        bankId: "",
        refId: referenceId,
        utr: utrNumber,
        tgProviderId: "PG123",
      });

      const data = await response.data;
      if (data.status !== "RS_OK") {
        toast.error(data.errorDescription);
      } else {
        if(activeWallet === "POINTS"){
          toast.success("transaction saved")
        }else{
          toast.success("transaction saved, please switch wallet to Points");
        }
      }
    } catch (error) {
      console.log("There was a problem with the fetch operation.");
    } finally {
      setLoading(false);
    }
  };

  const onProcessClose = () => {
    setOpenProcess(false);
    setUtrNumber("");
    setSelectedPType("");
  }

  return (
    <>
      <div onClick={onClick} className="flex gap-5 justify-between p-4 mb-2 w-full rounded-lg bg-meitms-color">
        <div className="flex gap-3 items-center font-medium h-[41px]">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-border-color">
            <img
              src={arrowIcon}
              alt={type === "DEPOSIT" || type === "CRYPTO_DEPOSIT" ? "Deposit" : "Withdrawal"}
              className="object-contain w-6 h-6"
            />
          </div>
          <div className="flex flex-col justify-center self-stretch my-auto">
            <div className="text-sm text-white">{title}</div>
            {amount && (
              <div className="mt-1 text-xs text-text-color">{amount}</div>
            )}
          </div>
        </div>
        <div className="self-start text-xs text-right text-text-color">
          {date}
          <div className={`flex ${(status !== "success" && type === "DEPOSIT") ? "justify-between" : "justify-end"}`}>
            {
              (type === "DEPOSIT") && (
                <AddCircleRoundedIcon
                  className=""
                  onClick={(e) => {
                    e.stopPropagation();
                    if (status === "initiated") {
                      setOpenProcess(true);
                    } else if (status !== "success") {
                      // Call the original function for other cases except success
                      setOpenUtr(true);
                    }
                  }}
                />
              )
            }
            <div className={`mt-1 text-sm ${statusColor} capitalize`}>{status}</div>
          </div>
        </div>
      </div>
      {
        openUtr && (
          <Dialog
            fullScreen={false}
            open={openUtr}
            onClose={() => setOpenUtr(false)}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '5rem'
              }
            }}
          >
            <div className="dialog-bg bg-inputbox-color relative p-4">
              <CloseIcon
                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                onClick={() => setOpenUtr(false)}
              />
              <div>
                <div>
                  UTR Resubmission
                </div>
                <div>
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className="mt-4 w-full max-w-md mx-auto"
                    >
                      <div className="mb-4">
                        <label htmlFor="utrInput" className="block mt-3 text-sm text-zinc-400">
                          UTR Number
                        </label>
                        <input
                          type="text"
                          className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                          id="utrInput"
                          placeholder="Enter UTR Number"
                          onChange={(e) => setUtrNumber(e.target.value)}
                        />
                      </div>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="p-2.5 w-full text-sm text-white whitespace-nowrap bg-red-600 rounded-lg border border-red-600 border-solid leading-[1.4rem]"
                          onClick={() => setOpenUtr(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className={`p-2.5 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${(loading || utrNumber === "") ? "opacity-50" : ""
                            }`}
                          disabled={(loading || utrNumber === "")}
                        >
                          <span className="submit-label">
                            {loading ? "Processing..." : "Submit"}
                          </span>
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
      {
        openProcess && (
          <Dialog
            fullScreen={false}
            open={openProcess}
            onClose={() => onProcessClose()}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '5rem'
              }
            }}
          >
            <div className="h-dialog-bg bg-inputbox-color relative p-4">
              <CloseIcon
                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                onClick={() => onProcessClose()}
              />
              <div>
                <div>
                  Deposit
                </div>
                <div>
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        processDeposit();
                      }}
                      className="mt-4 w-full max-w-md mx-auto"
                    >
                      <div className="w-full mt-3 mb-3">
                        <select
                          className="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color"
                          value={selectedPType} onChange={(e) => setSelectedPType(e.target.value)}>
                          <option value="" disabled>
                            Select Payment Type
                          </option>
                          {paymentType?.map((option: any) => (
                            <option value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="utrInput" className="block mt-3 text-sm text-zinc-400">
                          UTR Number
                        </label>
                        <input
                          type="text"
                          className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                          id="utrInput"
                          placeholder="Enter UTR Number"
                          onChange={(e) => setUtrNumber(e.target.value)}
                        />
                      </div>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="p-2.5 w-full text-sm text-white whitespace-nowrap bg-red-600 rounded-lg border border-red-600 border-solid leading-[1.4rem]"
                          onClick={() => onProcessClose()}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className={`p-2.5 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${(loading || utrNumber === "" || selectedPType === "") ? "opacity-50" : ""
                            }`}
                          disabled={(loading || utrNumber === "" || selectedPType === "")}
                        >
                          <span className="submit-label">
                            {loading ? "Processing..." : "Submit"}
                          </span>
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    activeWallet: state.userDetails?.activeWallet,
  };
};

export default connect(mapStateToProps)(TransactionHistoryItem);
