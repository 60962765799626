import React, { useEffect, useState, useCallback, useRef } from "react";
import API from "../../../services";
import { setActiveWallet, setUserDetails, setWallets } from "../../../store/authReducer";
import { connect, useDispatch } from "react-redux";
import { UserDetails } from "../../../models/UserDetails";
import { RootState } from "../../../models/RootState";
import { toast } from "react-toastify";
import "./BetList.scss";
import Loader from "../../../assets/images/Strike_White_Logo_Transparent_GIF.gif";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from "react-router";
import Pagination from "../Pagination";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface CustomerSupportProps {

}



const BetList: React.FC<CustomerSupportProps> = ({ }) => {


    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();
    const [count, setCount] = useState<any>(0);
    const [page1, setPage1] = useState(1);



    const dateFormat = (time: any) => {
        const matchStartTime = new Date(time);

        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });

        return formattedStartTime
    }


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getBetList();
    };

    const isFirstRender = useRef(true);


    const getBetList = async () => {
        if (isFirstRender.current) {
            setLoading(true);
            isFirstRender.current = false;
        }

        let response;
        try {
            response = await API.post(`/users/bet-list`, {
                page: page1,
                pageSize: rowsPerPage,
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
        }

        if (response && response.data?.bets) {
            setTickets(response?.data?.bets);
            if (page1 === 0 || page1 === 1) {
                setCount(response?.data?.total);
            }
        }
        setLoading(false);
    };


    useEffect(() => {
        getBetList();
    }, [])

    const copyToClipboard = (text: any) => {
        navigator?.clipboard?.writeText(text)?.then(() => {
        });
    }

    const handleRowClick = (row: any) => {
        navigate("/bet-details", { state: { details: row } });
    };



    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] bet-list">
                {
                    loading ? (
                        <div className="home-loading">
                            <img src={Loader} style={{ height: '10rem' }} />
                        </div>
                    ) : (
                        <>
                            <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem] w-full justify-between">
                                <h2 className="self-stretch my-auto">Bet List</h2>
                            </header>
                            <div className="overflow-x-auto">
                                <table className="min-w-full border-collapse user-table border-x mt-1">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 text-left border-b border-t">Bet Id</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Category</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Status</th>
                                            <th className="px-4 py-2 text-left border-b border-t">P/L</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Date</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Play Chips</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Points</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Event Name</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Market Name</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Sport Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.map((row: any, index: number) => (
                                            <tr key={index} className="border-b"
                                                onClick={() => handleRowClick(row)}
                                            >
                                                <td className="p-2">
                                                    <div className="flex">
                                                        <span
                                                            className="truncate max-w-[140px]"
                                                            title={row?.betId}
                                                        >
                                                            {row?.betId}
                                                        </span>
                                                        <ContentCopyIcon
                                                            className="cursor-pointer hover:text-gray-800 ml-2"
                                                            onClick={() => copyToClipboard(row?.betId)}
                                                            titleAccess="Copy to clipboard"
                                                            style={{ height: "1rem" }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="p-2">{row?.category}</td>
                                                <td className="p-2">
                                                    {row?.status}
                                                </td>
                                                <td
                                                    className={`p-2 ${row?.netAmout > 0
                                                        ? 'text-green-500'
                                                        : row?.netAmout < 0
                                                            ? 'text-red-500'
                                                            : 'text-white'
                                                        }`}
                                                >
                                                    {row?.netAmout
                                                        ? row?.netAmout % 1 === 0
                                                            ? row?.netAmout
                                                            : row?.netAmout.toFixed(2)
                                                        : 0}
                                                </td>
                                                <td className="px-2 py-2">
                                                    {dateFormat(row?.betTime)}
                                                </td>
                                                <td className="p-2">{row?.playChips}</td>
                                                <td className="p-2">{row?.points}</td>
                                                <td className="p-2">{row?.eventName}</td>
                                                <td className="p-2">{row?.marketName}</td>
                                                <td className="p-2">{row?.sportName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            {/* <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className="table-pagination"
                            /> */}
                            <Pagination currentPage={page1} setCurrentPage={setPage1} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} eventsCount={count} callBackFunc={getBetList} />
                        </>
                    )
                }
            </div>
        </>
    );
};



export default BetList;
