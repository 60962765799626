import React, { useEffect, useState, useCallback, useRef } from "react";
import BalanceInfo from "./BalanceInfo";
import CurrencySelector from "./CurrencySelector";
import TransactionAccordion from "../WalletComponents/TransactionAccordion";
import BalnceCard from "./../../assets/images/balance-card.svg";
import API from "../../services";
import { setActiveWallet, setUserDetails, setWallets } from "../../store/authReducer";
import { connect, useDispatch } from "react-redux";
import { UserDetails } from "../../models/UserDetails";
import { RootState } from "../../models/RootState";
import ActionButton from "./ActionButton";
import CurrencyTabs from "../WalletComponents/CurrencyTabs";
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from "react-toastify";
import DepositForm from "../WalletComponents/DepositForm";
import WithdrawForm from "../WalletComponents/WithdrawForm";
import { TxnList } from "../../models/TransactionList";
import TransactionHistoryItem from "../WalletComponents/TransactionHistory/TransactionHistoryItem";
import "./BalanceCard.scss";
import { useNavigate } from "react-router";

interface BalanceCardProps {
  userDetails: UserDetails;
  wallets: any;
  activeWallet: any;
  setActiveWallet: (data: any) => void;
  setWallets: (data: any) => void;
  setUserDetails: (data: any) => void;
}

const BalanceCard: React.FC<BalanceCardProps> = ({
  userDetails,
  wallets,
  activeWallet,
  setActiveWallet,
  setWallets,
  setUserDetails,
}) => {
  const [details, setDetails] = useState<UserDetails | null>(null);
  const [openAccordion, setOpenAccordion] = useState<string | null>("");
  const dispatch = useDispatch();
  const userKey = new URLSearchParams(window.location.search).get("id");
  const [activeAction, setActiveAction] = useState<string>("deposit");
  const [selectedBalance, setSelectedBalance] = useState<any>();
  const [currencies, setCurrencies] = useState<any>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setDetails(userDetails);
    handleAccordionToggle("Deposit");
    getRefId();
  }, []);

  const handleActionClick = (action: "deposit" | "withdraw") => {
    setActiveAction(action);
  };

  const formatDate = (epochTime: number): string => {
    const now = new Date();
    const date = new Date(epochTime);
    const diffTime = now.getTime() - date.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return "Today";
    if (diffDays === 1) return "Yesterday";
    if (diffDays < 7)
      return date.toLocaleDateString("en-US", { weekday: "long" });
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const dateFormat = (time: any) => {
    const matchStartTime = new Date(time);

    const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    return formattedStartTime
  }

  const [activeTab, setActiveTab] = useState<string>('Deposit');
  const [txnList, setTxnList] = useState<TxnList[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [amtLoading, setAmtLoading] = useState(false);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    if (tab === "History") {
      loadMoreTransactions();
    } else {
      setLoading(false);
      setHasMore(true);
    }
  };

  const handleRowClick = (row: any) => {
    navigate("/transaction-history-details", { state: { details: row } });
  }

  const loadMoreTransactions = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const userKey = sessionStorage.getItem("userKey");
      const res = await API.post("/account/get-transactions", {
        userKey,
        page,
        pageSize: 50,
      });

      if (res.data.status === "RS_OK") {
        const data = res?.data?.transactions || [];
        setTxnList((prev) => [...prev, ...data]);
        setHasMore(data.length === 50);
        setPage((prev) => prev + 1);
      } else {
        console.error("Error fetching transactions");
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to load transactions", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [page, hasMore, loading]);

  useEffect(() => {
    const handleIntersection = ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting && !loading && hasMore) {
        loadMoreTransactions();
      }
    };

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    const sentinel = document.getElementById("sentinel");
    if (sentinel) {
      observer.current.observe(sentinel);
    }

    return () => {
      if (observer.current && sentinel) {
        observer.current.unobserve(sentinel);
      }
    };
  }, [loadMoreTransactions, loading, hasMore]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const matchingWallet = wallets?.find(
  //       (wallet: any) => wallet?.walletName === activeWallet
  //     );
  //     setSelectedBalance(matchingWallet);
  //     const currencies = wallets?.map((wallet: any) => wallet?.walletTitle);
  //     setCurrencies(currencies);
  //     console.log("asdfghj",currencies, activeWallet)
  //   }, 1000)
  // }, [])

  useEffect(() => {
    const matchingWallet = wallets?.find(
      (wallet: any) => wallet?.walletName === "POINTS"
    );
    setSelectedBalance(matchingWallet);
    const currencies = wallets?.map((wallet: any) => wallet?.walletTitle);
    setCurrencies(currencies);
  }, [wallets])

  const handleAccordionToggle = (title: string) => {
    if (details?.MobileNumber) {
      setOpenAccordion(openAccordion === title ? null : title);
    }
  };

  const getUserBalance = async () => {

    let response: any;
    try {
      response = await API.post(`/webapp/get-user-balance`, {
        userKey: sessionStorage.getItem("userKey"),
      });
    } catch (e) {
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errDescription);
    }

    if (response && response.data.wallets) {
      setWallets(response.data?.wallets);
      setActiveWallet(response?.data?.activeWallet)
      const matchingWallet = response.data?.wallets.find(
        (wallet: any) => wallet?.walletName === "POINTS"
      );
      setSelectedBalance(matchingWallet);
    }
  };

  const getRefId = async () => {
    try {
      setAmtLoading(true);
      const userKey = sessionStorage.getItem("userKey");
      if (!userKey) {
        console.error("User ID not found in session storage");
        return;
      }
      const response = await API.post(`/users/get-user-details`, {
        userKey: userKey,
      });

      if (!response || response.data.status !== "RS_OK") {
        console.error("Error retrieving user details or invalid status");
        return;
      }

      const fetchedUserDetails: UserDetails = response.data?.userDetails;

      if (fetchedUserDetails) {
        setUserDetails(fetchedUserDetails);
      } else {
        console.error("User details not found in response");
      }
      setAmtLoading(false)
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const WDAmount = () => {
    let x = (userDetails?.Wager / 5) - userDetails?.WithdrawAmount;

    if (x > (selectedBalance?.balance - selectedBalance?.exposure)) {
      x = (selectedBalance?.balance - selectedBalance?.exposure)
    }
    return x?.toFixed(2);
  }

  const reFresh = () => {
    getRefId();
  }

  const tabs = [
    {
      label: "Deposit",
      content: (
        <>
          <DepositForm activeTab="Deposit" action="fiat" ></DepositForm>
        </>
      )
    },
    {
      label: "WithDraw",
      content: (
        <>
          <WithdrawForm activeTab="Withdraw" action="fiat" withdrawable={WDAmount()} ></WithdrawForm>
        </>
      )
    },
    {
      label: "History",
      content: (
        <>
          <div className="pt-3">
            {txnList.map((transaction, index) => (
              <TransactionHistoryItem
                key={index}
                referenceId={transaction?.referenceId}
                type={transaction.transactionType}
                title={transaction.transactionType}
                amount={transaction.amount}
                date={dateFormat(transaction.time)}
                status={transaction.status.toLowerCase()}
                onClick={() => handleRowClick(transaction)}
              />
            ))}
            {loading && <p className="text-white justify-center flex">Loading...</p>}
            <div id="sentinel" />
          </div>
        </>
      )
    }
  ]


  return (
    <>
      <section className="flex flex-col justify-center max-w-[388px]">
        <div className="flex relative flex-col p-4 pb-2 w-full">
          <img
            loading="lazy"
            src={BalnceCard}
            alt=""
            className="object-cover absolute inset-0 size-full max-md:min-w-[92vw] md:min-w-[28rem]"
          />
          <div className="flex relative gap-5 justify-between">
            <div className="flex flex-col">
              <div className="text-xs text-zinc-400">Available balance</div>
              <div className="flex items-center">
                <div className="text-lg font-semibold text-white">{(selectedBalance?.balance - selectedBalance?.exposure).toFixed(2)}</div>
                <RefreshIcon
                  className="ml-2 text-border-color"
                  style={{ height: '20px' }}
                  onClick={getUserBalance}
                />
              </div>
            </div>

            <CurrencySelector currency={"POINTS"} curList={currencies} />
          </div>
          <div className="flex relative justify-between">
            <div>
              <div className="text-xs text-zinc-400">Total Wager</div>
              <div className="flex items-center">
                <div className="text-lg font-semibold text-white">{userDetails?.Wager}</div>
              </div>
            </div>
            <div>
              <div className="text-xs text-zinc-400">Withdrawable</div>
              <div className="flex items-center">
                <div className="text-lg font-semibold text-white withdrawal-hl">{ amtLoading ?"..." : WDAmount()}</div>
                <RefreshIcon
                  className="ml-2 text-border-color"
                  style={{ height: '20px' }}
                  onClick={reFresh}
                />
              </div>
            </div>
          </div>
          {/* <div className="flex relative gap-3 justify-center mt-2.5 text-sm font-medium text-white whitespace-nowrap">
            <ActionButton
              label="Deposit"
              onClick={() => handleActionClick('deposit')}
              isActive={activeAction === 'deposit'}
            />
            <ActionButton
              label="Withdraw"
              onClick={() => handleActionClick('withdraw')}
              isActive={activeAction === 'withdraw'}
            />
          </div> */}
          {/* <div className="mt-4"></div> */}
        </div>
      </section>
      {/* <div>
        <TransactionAccordion
          title="Deposit"
          isOpen={openAccordion === "Deposit"}
          onToggle={handleAccordionToggle}
        />
        <TransactionAccordion
          title="Withdraw"
          isOpen={openAccordion === "Withdraw"}
          onToggle={handleAccordionToggle}
        />
      </div> */}

      <div className="wallet-nav">
        <div className="nav nav-pills" role="tablist">
          {tabs.map((tab) => (
            <div className="nav-item" key={tab.label}>
              <a
                role="tab"
                className={`nav-link ${activeTab === tab.label ? "active" : ""}`}
                onClick={() => handleTabClick(tab.label)}
              >
                {tab.label}
              </a>
            </div>
          ))}
        </div>

        <div className="tab-content">
          {tabs.map((tab) => (
            <div
              key={tab.label}
              className={`tab-pane fade ${activeTab === tab.label ? "show active" : ""
                }`}
              id={`tabs-tabpane-${tab.label}`}
              role="tabpanel"
            >
              {activeTab === tab.label && tab.content}
            </div>
          ))}
        </div>
      </div>
      {/* <CurrencyTabs action={activeAction} /> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
    wallets: state.userDetails?.wallets,
    activeWallet: state.userDetails?.activeWallet,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setWallets: (data: any) => {
      dispatch(setWallets(data));
    },
    setActiveWallet: (data: any) => {
      dispatch(setActiveWallet(data));
    },
    setUserDetails: (data: any) => {
      dispatch(setUserDetails(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceCard);
