import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";
import API from "../../../services";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface CustomerSupportProps {

}


const TicketDetails: React.FC<CustomerSupportProps> = ({ }) => {
    const location = useLocation();
    const { details, ticketId } = location.state || {};
    const [newTicket, setNewTicket] = useState<any>(false);
    const [newTicDes, setNewTicDes] = useState<any>("");
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [ticDetails, setTicDetails] = useState<any>();

    const dateFormat = (time: any) => {
        const matchStartTime = new Date(time * 1000);

        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });

        return formattedStartTime
    }


    const handleBackClick = () => {
        navigate(-1);
    };

    const getSupportTicket = async () => {
        // if (isFirstRender.current) {
        //     setLoading(true);
        //     isFirstRender.current = false;
        // }

        let response;
        try {
            response = await API.post(`/webapp/get-ticket`, {
                ticketId: ticketId
            });
        } catch (e) {
            console.log(e);
            // setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
        }

        if (response && response.data?.ticket) {
            setTicDetails(response?.data?.ticket)
        }
        // setLoading(false);
    };

    useEffect(() => {
        if (details) {
        //   console.log("Received details:");
          setTicDetails(details)
        }
      
        if (ticketId) {
        //   console.log("Received TicketId:", ticketId);
          getSupportTicket();
        }
      }, [details, ticketId]);

    const handleWithDrawSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await API.post(`/webapp/update-support-ticket`, {
                comment: newTicDes,
                status: ticDetails?.Status,
                ticketId: ticDetails?.TicketId
            });

            const data = response.data;
            if (data?.status !== "RS_OK") {
                toast.error(`${data.errorDescription}`);
                return;
            } else {
                toast.success("Ticket Updated Successfully");
                navigate("/customersupport");
            }
            setNewTicDes("");
            setNewTicket(false);

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
        }
    };

    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] customer-support">
                <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem] w-full justify-between">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={handleBackClick}
                        className="cursor-pointer object-contain shrink-0 self-stretch my-auto w-[12px] aspect-[0.57]"
                    />
                    <h2 className="self-stretch my-auto">Ticket Details</h2>
                    <button
                        onClick={() => setNewTicket(true)}
                        className="flex items-center justify-between px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                    >
                        <span>+ New Comment</span>
                    </button>
                </header>
                <div className="p-2">
                    <div className="mb-4">
                        <p className="text-base font-medium ">TicketId</p>
                        <div className="p-1">
                            <p className="text-sm  text-white">{ticDetails?.TicketId}</p>
                        </div>
                    </div>

                    <div className="mb-4">
                        <p className="text-base font-medium ">Created At</p>
                        <div className="p-1">
                            <p className="text-sm  text-white">{dateFormat(ticDetails?.CreatedAt)}</p>
                        </div>
                    </div>

                    <div className="mb-4">
                        <p className="text-base font-medium">Description</p>
                        <div className="p-1">
                            <p className="text-sm text-white">
                                {ticDetails?.Description}
                            </p>
                        </div>
                    </div>

                    {ticDetails?.ReferenceId && ticDetails?.ReferenceId !== "N/A" && (
                        <div className="mb-4">
                            <p className="text-base font-medium ">Reference Id</p>
                            <div className="p-1">
                                <p className="text-sm  text-white">{ticDetails?.ReferenceId}</p>
                            </div>
                        </div>
                    )}


                    <div className="mb-4">
                        <div className="text-base font-medium">
                            Status: <span className="text-sm text-white font-normal">{ticDetails?.Status}</span>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="text-base font-medium">
                            Category: <span className="text-sm text-white font-normal">{ticDetails?.Category}</span>
                        </div>
                    </div>
                    {
                        (ticDetails?.Updates && ticDetails?.Updates?.length > 0) ? <>
                            <div className="mb-4">
                                <p className="text-base font-medium">Updates:</p>
                                <div className="p-1">
                                    {
                                        ticDetails?.Updates?.map((update: any) => (
                                            <div>
                                                <p className="text-sm  text-white"><strong>{update?.UpdatedBy}: </strong>{update?.Comment}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </> : <></>
                    }
                </div>


            </div>

            {
                newTicket && (
                    <Dialog
                        fullScreen={false}
                        open={newTicket}
                        onClose={() => setNewTicket(false)}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '5rem'
                            }
                        }}
                    >
                        <div className="dialog-bg bg-inputbox-color relative p-4">
                            <CloseIcon
                                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                                onClick={() => setNewTicket(false)}
                            />
                            <div>
                                <div>
                                    Add A Comment
                                </div>
                                <div>
                                    <>
                                        <form className="flex flex-col w-full" onSubmit={handleWithDrawSubmit}>
                                            <label
                                                htmlFor="amount"
                                                className="block mt-2 text-sm text-zinc-400"
                                            >
                                                Comment
                                            </label>
                                            <input
                                                type="text"
                                                className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                placeholder="Enter you Issue"
                                                value={newTicDes || ""}
                                                onChange={(e) => setNewTicDes(e.target.value)}
                                            />

                                            <div className="flex justify-between mt-4">
                                                <button
                                                    type="submit"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                                                    disabled={!newTicDes}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                                                    onClick={() => { setNewTicket(false) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )
            }
        </>
    );
};

export default TicketDetails;