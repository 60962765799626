import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetails } from "../models/UserDetails";

export interface UserDetailsDTO {
  userDetails: UserDetails;
  wallets: any;
  sportsData: any;
  casinoData: any;
  homeData: any;
  banners: any;
  activeWallet: any;
  Thumbnail: any;
}

// Define the initial state
const initialState: UserDetailsDTO = {
  userDetails: {
    EmailId: "",
    IsAffiliator: false,
    MobileNumber: "",
    ReferralCode: "",
    ReferralCount: 0,
    ReferrerId: "",
    Status: "",
    UserKey: "",
    UserName: "",
    ActiveWallet: "",
    Theme: "",
    Thumbnail: "",
    Wager: 0,
    WithdrawAmount: 0,
  },
  wallets: [],
  sportsData: [],
  casinoData: [],
  homeData: [],
  banners: [],
  activeWallet: "",
  Thumbnail: "",
};

// Create a slice of the state with actions and reducers
const authSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails(state, action: PayloadAction<Partial<UserDetails>>) {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    setWallets(state, action: PayloadAction<any[]>) {
      state.wallets = action.payload;
    },
    setSportsData(state, action: PayloadAction<any[]>) {
      state.sportsData = action.payload;
    },
    setCasinoData(state, action: PayloadAction<any[]>) {
      state.casinoData = action.payload;
    },
    setHomeData(state, action: PayloadAction<any[]>) {
      state.homeData = action.payload;
    },
    setBanners(state, action: PayloadAction<any[]>) {
      state.banners = action.payload;
    },
    setActiveWallet(state, action: PayloadAction<any>) {
      state.activeWallet = action.payload;
    },
    setUserThumbnail(stage, action: PayloadAction<any>) {
      stage.Thumbnail = action.payload;
    },
    setUserName(state, action: PayloadAction<any>) {
      state.userDetails.UserName = action.payload;
    }
    // Define other reducers here if needed
  },
});

// Export the actions generated by createSlice
export const { setUserDetails, setWallets, setSportsData, setCasinoData, setHomeData, setBanners, setActiveWallet, setUserThumbnail, setUserName } = authSlice.actions;

// Export the reducer to be used in the store
export default authSlice.reducer;
