import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import API from "../../../services";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface TransactionHistoryItemProps { }


const TransactionHistoryDetails: React.FC<TransactionHistoryItemProps> = () => {
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [categories, setCategories] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedCategory, setSelectedCategory] = useState<any>("");
    const [selectedStatus, setSelectedStatus] = useState<any>("");
    const [tickets, setTickets] = useState<any>([]);
    //const [status, setStatus] = useState<any>([]);
    const [count, setCount] = useState<any>(0);
    const [newTicket, setNewTicket] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const [newTicDes, setNewTicDes] = useState<any>("");
    const [newReferenceID, setNewReferenceID] = useState<any>("");
    const [newTicCat, setNewTicCat] = useState<any>("");
    const { details } = location.state || {};
    const navigate = useNavigate()
    const dateFormat = (time: number) => {
        const matchStartTime = new Date(time);
        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });
        return { formatted: formattedStartTime };
    };






    const handleTicketsuccess = () => {
        navigate("/ticket-details", { state: { ticketId: details?.ticketId } });
    };

    useEffect(() => {
        setNewTicCat(details?.transactionType)
    }, []);


    const handleBackClick = () => {
        navigate(-1);
    };

    const handleWithDrawSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await API.post(`/webapp/create-support-ticket`, {
                category: details?.transactionType,
                description: newTicDes,
                referenceID: details?.referenceId
            });
            const data = response.data;
            if (data.status !== "RS_OK") {
                toast.error(data.errorDescription);
                setNewTicket(false);
                return;
            }
            toast.success("Ticket Created Successfully");
            setNewTicDes("");
            setNewReferenceID(true);
            setNewTicket(false);
            navigate("/customersupport")
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoading(false);
        }
    };

    if (!details) {
        return <div>No details available</div>;
    }

    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] transaction-history">
                <header className="relative flex items-center text-base font-medium text-white mb-[0.5rem] w-full">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={handleBackClick}
                        className="cursor-pointer object-contain shrink-0 w-[12px] aspect-[0.57] mr-2"
                    />
                    <h2 className="flex-grow">Transaction Details</h2>
                    {
                        details?.ticketId !== "" ? (
                            <button
                                onClick={() => handleTicketsuccess()}
                                className="absolute right-0 px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                            >
                                <span>View Ticket</span>
                            </button>
                        ) : (
                            <button
                                onClick={() => setNewTicket(true)}
                                className="absolute right-0 px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                            >
                                <span>+ New Ticket</span>
                            </button>
                        )
                    }
                </header>
                <div className="p-2">
                    <div className="mb-2">
                        <p className="text-base font-medium text-white">Reference ID</p>
                        <div className="p-1 flex items-center space-x-2">
                            <p className="text-sm text-white">{details?.referenceId}</p>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(details?.referenceId || "");
                                    // alert("Reference ID copied to clipboard!");
                                }}
                                className="px-2 py-1 text-xs text-white bg-primary-color rounded hover:bg-primary-dark transition flex items-center space-x-1"
                            >
                                <FontAwesomeIcon icon={faCopy} className="text-white" />

                            </button>
                        </div>
                    </div>
                    <div className="mb-2">
                        <p className="text-base font-medium text-white">Transaction Type</p>
                        <div className="p-1">
                            <p className="text-sm text-white">{details?.transactionType}</p>
                        </div>
                    </div>

                    <div className="mb-2">
                        <p className="text-base font-medium text-white">Amount</p>
                        <div className="p-1">
                            <p className="text-sm text-white">{details?.amount}</p>
                        </div>
                    </div>
                    {(details?.transactionType === "CRYPTO_WITHDRAWAL" || details?.transactionType === "CRYPTO_DEPOSIT") && (
                        <>
                            <div className="mb-2">
                                <p className="text-base font-medium text-white">Crypto Amount</p>
                                <div className="p-1">
                                    <p className="text-sm text-white">{details?.cryptoAmount}</p>
                                </div>
                            </div>
                            <div className="mb-2">
                                <p className="text-base font-medium text-white">Currency</p>
                                <div className="p-1">
                                    <p className="text-sm text-white">{details?.currency}</p>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="mb-2">
                        <p className="text-base font-medium text-white">Status</p>
                        <div className="p-1">
                            <p className="text-sm text-white">{details?.status}</p>
                        </div>
                    </div>

                    {details?.transactionMethod && details.transactionMethod !== "N/A" && (
                        <div className="mb-2">
                            <p className="text-base font-medium text-white">Transaction Method</p>
                            <div className="p-1">
                                <p className="text-sm text-white">{details.transactionMethod}</p>
                            </div>
                        </div>
                    )}
                    {details?.utr && details.utr !== "N/A" && (
                        <div className="mb-2">
                            <p className="text-base font-medium text-white">UTR</p>
                            <div className="p-1">
                                <p className="text-sm text-white">{details?.utr}</p>
                            </div>
                        </div>
                    )}
                    <div className="mb-2">
                        <p className="text-base font-medium text-white">Time</p>
                        <div className="p-1">
                            <p className="text-sm text-white">{dateFormat(details?.time)?.formatted}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                newTicket && (
                    <Dialog
                        fullScreen={false}
                        open={newTicket}
                        onClose={() => setNewTicket(false)}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '5rem'
                            }
                        }}
                    >
                        <div className="dialog-bg bg-inputbox-color relative p-4">
                            <CloseIcon
                                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                                onClick={() => setNewTicket(false)}
                            />
                            <div>
                                <div>
                                    Create New Ticket
                                </div>
                                <div>
                                    <>
                                        <form className="flex flex-col w-full" onSubmit={handleWithDrawSubmit}>
                                            <div className="w-full mt-3">
                                                <label
                                                    htmlFor="amount"
                                                    className="block mt-2 text-sm text-zinc-400"
                                                >
                                                    Category
                                                </label>
                                                <input
                                                    type="text"
                                                    className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                    placeholder="Enter you Issue"
                                                    value={newTicCat || ""}
                                                    disabled
                                                />
                                            </div>
                                            <label
                                                htmlFor="amount"
                                                className="block mt-2 text-sm text-zinc-400"
                                            >
                                                Description
                                            </label>
                                            <input
                                                type="text"
                                                className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                placeholder="Enter you Issue"
                                                value={newTicDes || ""}
                                                onChange={(e) => setNewTicDes(e.target.value)}
                                            />
                                            <div className="flex justify-between mt-4">
                                                <button
                                                    type="submit"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                                                    disabled={!newTicCat || !newTicDes}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                                                    onClick={() => { setNewTicket(false) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )
            }
        </>
    );
};


export default TransactionHistoryDetails;
