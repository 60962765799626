import React, { useEffect, useState, useCallback, useRef } from "react";
import BalnceCard from "./../../assets/images/balance-card.svg";
import API from "../../../services";
import { setActiveWallet, setUserDetails, setWallets } from "../../../store/authReducer";
import { connect, useDispatch } from "react-redux";
import { UserDetails } from "../../../models/UserDetails";
import { RootState } from "../../../models/RootState";
import { toast } from "react-toastify";
import "./CustomerSupport.scss";
import Loader from "../../../assets/images/Strike_White_Logo_Transparent_GIF.gif";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import Pagination from "../Pagination";

interface CustomerSupportProps {

}



const CustomerSupport: React.FC<CustomerSupportProps> = ({ }) => {


    const [activeTab, setActiveTab] = useState<string>('Deposit');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchUser, setSearchUser] = useState<any>("");
    const [tickets, setTickets] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [status, setStatus] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();
    const [newTicket, setNewTicket] = useState<any>(false);
    const [newTicDes, setNewTicDes] = useState<any>("");
    const [newTicCat, setNewTicCat] = useState<any>("");
    const [refId, setRefId] = useState<any>("");
    const [selectedCategory, setSelectedCategory] = useState<any>("");
    const [selectedStatus, setSelectedStatus] = useState<any>("");
    const [count, setCount] = useState<any>(0);
    const isFirstRender = useRef(true);



    const dateFormat = (time: any) => {
        const matchStartTime = new Date(time * 1000);

        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });

        return formattedStartTime
    }


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getCategories = async () => {
        let response;
        try {
            response = await API.post(`/webapp/get-support-categories`, {});
        } catch (e) {
            return;
        }
        if (response.data?.status !== "RS_OK") {
            navigate("/home");
            return;
        }

        if (response && response.data?.categories) {
            setCategories(response?.data?.categories)
        }
    }

    const getStatus = async () => {
        let response;
        try {
            response = await API.post(`/webapp/get-support-statuses`, {});
        } catch (e) {
            return;
        }
        if (response.data?.status !== "RS_OK") {
            navigate("/home");
            return;
        }
        if (response && response.data?.statuses) {
            setStatus(response?.data?.statuses)
        }
    }

    const getSupportTickets = async () => {
        if (isFirstRender.current) {
            setLoading(true);
            isFirstRender.current = false;
        }

        let response;
        try {
            response = await API.post(`/webapp/get-support-tickets`, {
                page: page,
                pageSize: rowsPerPage,
                category: selectedCategory,
                status: selectedStatus,
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
        }

        if (response && response.data?.tickets) {
            setTickets(response?.data?.tickets)
            if (page === 0 || page === 1) {
                setCount(response?.data?.count);
            }
        }
        setLoading(false);
    };

    const handleRowClick = (row: any) => {
        navigate("/ticket-details", { state: { details: row } });
    };

    
    const handleNewTicket = () => {
        navigate("/new-ticket", { state: { details: categories } });
    };

    useEffect(() => {
        getStatus();
        getCategories();
        getSupportTickets();
    }, [])

  const clearFields = () => {
    setNewTicket(false);
    setNewTicCat("");
    setNewTicDes("");
    setRefId("");
  }


    const tabs = [
        {
            label: "Deposit",
            content: (
                <>
                    {/* <DepositForm activeTab="Deposit" action="fiat" ></DepositForm> */}
                </>
            )
        },
        {
            label: "WithDraw",
            content: (
                <>
                    {/* <WithdrawForm activeTab="Withdraw" action="fiat" ></WithdrawForm> */}
                </>
            )
        },
        {
            label: "History",
            content: (
                <>
                    <div className="pt-3">
                        {/* {txnList.map((transaction, index) => (
            <TransactionHistoryItem
              key={index}
              type={transaction.transactionType}
              title={transaction.transactionType}
              amount={transaction.amount}
              date={formatDate(transaction.time)}
              status={transaction.status.toLowerCase()}
            />
          ))} */}
                        {loading && <p className="text-white justify-center flex">Loading...</p>}
                        <div id="sentinel" />
                    </div>
                </>
            )
        }
    ]


    const handleWithDrawSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await API.post(`/webapp/create-support-ticket`, {
                category: newTicCat,
                description: newTicDes,
                referenceID: refId
            });

            const data = response.data;
            if (data.status !== "RS_OK") {
                toast.error(`${data.errorDescription}`);
                return;
            } else {
                toast.success("Ticket Created Successful");
            }
            setNewTicDes("");
            setNewTicCat("");
            setRefId("");
            setNewTicket(false);
            getSupportTickets();

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getSupportTickets();
    }, [selectedStatus, selectedCategory]);
    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] customer-support">
                {
                    loading ? (
                        <div className="home-loading">
                            <img src={Loader} style={{ height: '10rem' }} />
                        </div>
                    ) : (
                        <>
                            <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem] w-full justify-between">
                                <h2 className="self-stretch my-auto">Tickets</h2>
                                <button
                                    onClick={() => handleNewTicket()}
                                    className="flex items-center justify-between px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                                >
                                    <span>+ New Ticket</span>
                                </button>
                            </header>
                            <div className="flex justify-between mb-1">
                                <div className="w-[49%]">
                                    <select
                                        className="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color"
                                        value={selectedCategory}
                                        onChange={(e) => {
                                            setSelectedCategory(e.target.value)
                                        }}
                                    >
                                        <option value="" disabled>
                                            Select Category
                                        </option>
                                        <option value="">
                                            All
                                        </option>
                                        {categories?.map((option: any) => (
                                            <option value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-[49%]">
                                    <select
                                        className="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color"
                                        value={selectedStatus} onChange={(e) => { setSelectedStatus(e.target.value) }}>
                                        <option value="" disabled>
                                            Select Status
                                        </option>
                                        <option value="">
                                            All
                                        </option>
                                        {status?.map((option: any) => (
                                            <option value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {

                            }
                            <div className="overflow-x-auto">
                                <table className="min-w-full border-collapse user-table border-x mt-1">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 text-left border-b border-t">Created At</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.map((row: any, index: number) => (
                                            <tr key={index} className="border-b"
                                                onClick={() => handleRowClick(row)}
                                            >
                                                <td className="px-2 py-2 flex items-center">
                                                    {/* Colored Circle */}
                                                    <span
                                                        className={`w-2.5 h-2.5 rounded-full mr-1 ${row?.Status === "NEW"
                                                            ? "bg-red-500"
                                                            : row?.Status === "IN-PROGRESS"
                                                                ? "bg-yellow-500"
                                                                : row?.Status === "CLOSED"
                                                                    ? "bg-green-500"
                                                                    : "bg-blue-500"
                                                            }`}
                                                    ></span>
                                                    {dateFormat(row?.CreatedAt)}
                                                </td>
                                                <td className="px-2 py-2">
                                                    <div
                                                        className="truncate max-w-[190px]"
                                                        title={row?.Description}
                                                    >
                                                        {row?.Description}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            {/* <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className="table-pagination"
                            /> */}
                            <Pagination currentPage={page} setCurrentPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} eventsCount={count} callBackFunc={getSupportTickets} />
                        </>
                    )
                }
            </div>

            {
                newTicket && (
                    <Dialog
                        fullScreen={false}
                        open={newTicket}
                        onClose={() => clearFields()}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '5rem'
                            }
                        }}
                    >
                        <div className="cus-dialog-bg bg-inputbox-color relative p-4">
                            <CloseIcon
                                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                                onClick={() => clearFields()}
                            />
                            <div>
                                <div>
                                    Create New Ticket
                                </div>
                                <div>
                                    <>
                                        <form className="flex flex-col w-full" onSubmit={handleWithDrawSubmit}>
                                            <div className="w-full mt-3">
                                                <select
                                                    className="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color"
                                                    value={newTicCat} onChange={(e) => setNewTicCat(e.target.value)}>
                                                    <option value="" disabled>
                                                        Select Category
                                                    </option>
                                                    {categories?.map((option: any) => (
                                                        <option value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <label
                                                htmlFor="amount"
                                                className="block mt-2 text-sm text-zinc-400"
                                            >
                                                Description
                                            </label>
                                            <input
                                                type="text"
                                                className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                placeholder="Enter you Issue"
                                                value={newTicDes || ""}
                                                onChange={(e) => setNewTicDes(e.target.value)}
                                            />

                                            {(newTicCat === "DEPOSIT" || newTicCat === "WITHDRAWAL" || newTicCat === "CRYPTO_DEPOSIT" || newTicCat === "CRYPTO_WITHDRAWAL") && (
                                                <>
                                                    <label
                                                        htmlFor="amount"
                                                        className="block mt-2 text-sm text-zinc-400"
                                                    >
                                                        Reference Id
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                        placeholder="Enter Reference Id"
                                                        value={refId || ""}
                                                        onChange={(e) => setRefId(e.target.value)}
                                                    />
                                                </>
                                            )}

                                            <div className="flex justify-between mt-4">
                                                <button
                                                    type="submit"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                                                    disabled={
                                                        !newTicCat ||
                                                        !newTicDes ||
                                                        ((newTicCat === "DEPOSIT" || newTicCat === "WITHDRAWAL" || newTicCat === "CRYPTO_DEPOSIT" || newTicCat === "CRYPTO_WITHDRAWAL") && !refId)
                                                    }
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="p-2.5 w-[48%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                                                    onClick={() => { clearFields() }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userDetails: state.userDetails?.userDetails,
        wallets: state.userDetails?.wallets,
        activeWallet: state.userDetails?.activeWallet,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setWallets: (data: any) => {
            dispatch(setWallets(data));
        },
        setActiveWallet: (data: any) => {
            dispatch(setActiveWallet(data));
        },
    };
};

export default CustomerSupport;
