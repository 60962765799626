import "./App.css";
import WalletPage from "./components/WalletComponents/WalletPage";
import DefaultPage from "./components/DefaultPage";
import SportsPage from "./components/SportsPage";
import WalletInfo from "./components/WalletComponents/WalletInfo";
import Footer from "./components/WalletComponents/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoAccessPage from "./components/no-access";
import IframePage from "./components/pages/IframePage";
import ProfilePage from "./components/WalletComponents/ProfilePage";
import TransactionHistory from "./components/WalletComponents/TransactionHistory/TransactionHistory";
import TransactionHistoryDetails from "./components/WalletComponents/TransactionHistory/TransactionHistoryDetails";
import CasinoIframe from "./components/pages/CasinoIframe";
import Site from "./components/pages/Site";
import HomePage from "./components/Home/Home";
import PlayGame from "./components/pages/PlayGame";
import BannerInfo from "./components/BannerInfo/BannerInfo";
import ProviderGames from "./components/ProviderGames/ProviderGames";
import { useEffect, useState } from "react";
import Loader from "../src/assets/images/Strike_White_Logo_Transparent_GIF.gif";
import { Backdrop } from "@mui/material";
import MyProfile from "./components/WalletComponents/MyProfile/MyProfile";
import "./theme/variables.css"
import CustomerSupport from "./components/pages/CustomerSupport/CustomerSupport";
import TicketDetails from "./components/pages/CustomerSupport/TicketDetails";
import BetList from "./components/pages/BetList/BetList";
import AccountStatement from "./components/pages/AccountStatement/AccountStatement";
import FAQ from "./components/pages/FAQ/FAQ";
import NewTicket from "./components/pages/CustomerSupport/NewTicket";
import BetDetails from "./components/pages/BetList/BetDetails";

interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultPage />} />
      <Route path="wallet" element={<WalletPage />} />
      <Route path="sports" element={<SportsPage />} />
      <Route path="casino" element={<CasinoIframe />} />
      <Route path="site" element={<Site />} />
      <Route path="iframe" element={<IframePage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="transactions" element={<TransactionHistory />} />
      <Route path="home" element={<HomePage />} />
      <Route path="play/:gameInfo" element={<PlayGame />} />
      <Route path="bannerinfo" element={<BannerInfo />} />
      <Route path="providergames" element={<ProviderGames />} />
      <Route path="myprofile" element={<MyProfile />} />
      <Route path="customersupport" element={<CustomerSupport />} />
      <Route path="/ticket-details" element={<TicketDetails />} />
      <Route path="/betlist" element={<BetList />} />
      <Route path="/account-statement" element={<AccountStatement />} />
      <Route path="/transaction-history-details" element={<TransactionHistoryDetails />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/new-ticket" element={<NewTicket />} />
      <Route path="/bet-details" element={<BetDetails />} />
    </Routes>
  );
};

const ConditionalRoutes = () => {
  const location = useLocation();
  const path = location.pathname;
  const key = new URLSearchParams(window.location.search).get("id");
  const [user, setUser] = useState<any>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const tg = (window as any)?.Telegram?.WebApp;
    tg?.ready();

    const userData: TelegramUser = tg?.initDataUnsafe?.user;
    if (userData) {
      setUser(userData);
    }

    const userId = userData?.id;

    console.log("userId", tg);

    if (key && userId) {
      const existingKey = sessionStorage.getItem("userKey");
      const newKey = `${key}-${userId}`;

      console.log("key", newKey, userData);

      if ((existingKey !== newKey) && (newKey?.split("-")?.length > 1)) {
        sessionStorage.setItem("userKey", newKey || "");
      } else {
      }
    } else {
      // navigate("/no-access");
    }
  }, []);


  const pathsToShow = ["/casino", "/wallet", "/sports", "/site", "/iframe", "/myprofile", "/profile", "/transactions", "/home", "/play", "/bannerinfo", "/providergames", "/betlist", "/account-statement", "/customersupport", "/ticket-details", "/transaction-history-details", "/new-ticket", "/bet-details", "/faq"];
  return (
    <>

      <Backdrop
        sx={{ color: "#051314", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="fullWidth">
          <img className="" src={Loader} alt="" />
        </div>
      </Backdrop>
      {
        !loading && (
          <>
            {path.startsWith("/play") ? (
              <AppRoutes />
            ) : pathsToShow.some((validPath) => path.startsWith(validPath)) ? (
              <>
                <WalletInfo balance="0.230089" />
                <AppRoutes />
                <Footer />
              </>
            ) : (
              <NoAccessPage />
            )}
          </>
        )
      }
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="flex flex-col mx-auto w-full bg-custom-bg bg-repeat bg-auto bg-cover min-h-screen gradient-div">
        <div className="flex flex-col w-full">
          <Routes>
            <Route path="/" element={<DefaultPage />} />
            <Route path="/*" element={<ConditionalRoutes />} />
          </Routes>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );
}

export default App;
